@import "./../variables";
@import "./media-breckpoints-mixin";

@mixin article-font-size() {
  h1 {
    font-size: px-to-rem(40);

    @include media-breakpoint-down(sm) {
      font-size: px-to-rem(24);
    }
  }

  p {
    font-size: px-to-rem(20);

    @include media-breakpoint-down(sm) {
      font-size: px-to-rem(16);
    }
  }
}
