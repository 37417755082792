@import "./../../assets/scss/mixins/buttons-mixin";
@import "./../../assets/scss/mixins/media-breckpoints-mixin";

.episode-player {
  display: flex;
  height: 100%;
  flex-direction: column;

  &.hidden {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
  }

  .episode-players {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    margin-bottom: px-to-rem(45);
    padding-top: px-to-rem(25);

    &.type-2 {
      border-left: px-to-rem(1) solid map-get($main-colors, 'border-color-light');
      border-right: px-to-rem(1) solid map-get($main-colors, 'border-color-light');
      margin-left: 5%;
      margin-right: 5%;

      @include media-breakpoint-up(md) {
        margin-left: 10%;
        margin-right: 10%;
      }
    }

    &.type-3 {
      border-left: px-to-rem(1) solid map-get($main-colors, 'border-color-light');
      border-right: px-to-rem(1) solid map-get($main-colors, 'border-color-light');
      margin-left: 5%;
      margin-right: 5%;

      @include media-breakpoint-up(md) {
        margin-left: 10%;
        margin-right: 10%;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 20%;
        margin-right: 20%;
      }

      .episode-players-wrapper {
        flex-direction: column;

        .secondary {
          flex-direction: row;
        }
      }
    }

    .episode-players-wrapper {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    .video-room {
      position: relative;
      flex: 1 0 calc(75% - #{px-to-rem(2)});
      padding: px-to-rem(1);

      .video-js {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        display: flex;
        align-items: center;
        justify-content: center;

        .vjs-big-play-button {
          left: auto;
          top: unset;
          margin-bottom: -56.25%;
        }
      }

      .room-buttons {
        position: absolute;
        right: 0;
        height: 100%;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .btn {
          margin: px-to-rem(10) px-to-rem(16);
        }
      }
    }

    .secondary {
      flex: 0 0 25%;
      display: flex;
      flex-direction: row;

      @include media-breakpoint-up(lg) {
        flex-direction: column;
      }

      .video-room {
        width: calc(100% - 2px);
        height: calc(33.33% - #{px-to-rem(1)});
        padding: px-to-rem(1);
        flex: auto;
        box-sizing: border-box;

        @include media-breakpoint-up(lg) {
          flex: 1 0 25%;
        }

        &:last-of-type {
          padding-bottom: unset;
          height: 33.33%;
        }

        button {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: unset;
          border: unset;
          z-index: 10;
          cursor: pointer;
        }

        button:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
