@import "./../../assets/scss/variables";
@import "./../../assets/scss/mixins/backrounds-mixin";
@import "./../../assets/scss/mixins/pin-for-paper-mixin";
@import "./../../assets/scss/mixins/typography-mixin";

.faq-page {
  display: flex;
  flex-direction: column;
  @include background-texture();
  @include fixed-background();
  background-attachment: fixed;

  .navigation-menu {
    background-color: transparent;
  }

  .faq-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: px-to-rem(20);

    @include media-breakpoint-up(md) {
      padding: px-to-rem(40) px-to-rem(60);
    }

    .faq-paper-background {
      background-image: url("../../../public/images/papers-and-pushpin/faq-content-paper-vertical.png");
      background-image: image-set(
          url("../../../public/images/papers-and-pushpin/faq-content-paper-vertical.webp")
          1x
      );
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: calc(100% - #{px-to-rem(5)} - #{px-to-rem(23)});
      padding: px-to-rem(2) px-to-rem(5) px-to-rem(7) px-to-rem(23);

      @include media-breakpoint-up(md) {
        background-image: url("../../../public/images/papers-and-pushpin/faq-content-paper.png");
        background-image: image-set(
            url("../../../public/images/papers-and-pushpin/faq-content-paper.webp")
            1x
        );
      }

      @include pin-for-paper() {
        margin-bottom: px-to-rem(10);
      };

      .faq-article {
        padding: px-to-rem(50) px-to-rem(10) px-to-rem(20) px-to-rem(10);
        color: map-get($text-colors, 'text-dark');

        @include media-breakpoint-up(md) {
          padding: px-to-rem(50) px-to-rem(40) px-to-rem(40) px-to-rem(40);
        }

        @include article-font-size();

        .faq-main-title {
          text-align: center;
        }

        .faq-article-paragraph {
          margin-bottom: px-to-rem(32);
        }

        .faq-article-ul {
          .faq-article-li {
            list-style-type: decimal;
            margin-left: px-to-rem(24.5);
            margin-bottom: px-to-rem(24);

            p:nth-child(1) {
              font-weight: 900;
            }

            a {
              text-decoration: underline;
            }

            b {
              font-weight: 900;
            }
          }

          .faq-article-li::marker {
            font-weight: 700;
          }
        }
      }
    }
  }
}