@import "./../../../../assets/scss/variables";
@import "./../../../../assets/scss/mixins/backrounds-mixin";

.activate-ticket-popup {
  .fullscreen-popup-body {
    padding: px-to-rem(10) px-to-rem(10 + 9) px-to-rem(10) px-to-rem(10);

    @include media-breakpoint-up(md) {
      padding: px-to-rem(20) px-to-rem(20 + 12) px-to-rem(20) px-to-rem(20);
    }

    @include media-breakpoint-up(xl) {
      padding: px-to-rem(40) px-to-rem(40 + 17) px-to-rem(40) px-to-rem(40);
    }
  }

  .activate-ticket-popup-container {
    display: flex;
    color: white;
    width: 100%;
    flex-direction: column;
    position: relative;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    @include media-breakpoint-up(xxl) {
      font-size: px-to-rem(28);
    }
  }

  .activate-ticket-popup-background {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
    @include background-texture();
  }
}
