@import "./../../../../assets/scss/mixins/media-breckpoints-mixin";
@import "./../../../../assets/scss/functions/functions";

.site-logo {
  max-height: calc(#{px-to-rem(96)} - #{px-to-rem(10)});
  padding-left: px-to-rem(28);
  padding-top: px-to-rem(3);
  z-index: map-get($z-index-positions, 'z-index-main-logo');

  @include media-breakpoint-down(sm) {
    padding-top: px-to-rem(12);
  }

  .site-logo-img {
    max-height: unset;
    min-width: unset;

    @include media-breakpoint-down(sm) {
      max-height: px-to-rem(56);
      min-width: px-to-rem(56);
    }

    @include media-breakpoint-down(lg) {
      max-height: px-to-rem(80);
    }
  }
}