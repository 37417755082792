@import "./../variables";
@import "./media-breckpoints-mixin";

@mixin pin-for-paper {
  .pin-box {
    display: flex;
    justify-content: center;

    .pin {
      position: absolute;
      width: px-to-rem(60);
      margin-left: px-to-rem(-30);
      z-index: 2;

      @include media-breakpoint-up(xl) {
        width: px-to-rem(80);
        margin-left: px-to-rem(-40);
      }
    }

    @content;
  }
}
