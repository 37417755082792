@import "./../../assets/scss/variables";

.spinner {
  --animation-duration: 1000ms;
  --size: 75px;

  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .spinner-item {
    width: calc(var(--size) / 12);
    height: 80%;
    animation: spinner var(--animation-duration) ease-in-out infinite;

    @keyframes spinner {
      50% {
        transform: scaleY(0.25);
      }
    }
  }

  .spinner-item:nth-child(1) {
    background: map-get($main-colors, "danger");
    animation-delay: calc(var(--animation-duration) / 10 * -3);
  }

  .spinner-item:nth-child(2) {
    background: rgba(map-get($main-colors, 'light'), 0.6);
    animation-delay: calc(var(--animation-duration) / 10 * -1);
  }

  .spinner-item:nth-child(3) {
    background: map-get($main-colors, "danger");
    animation-delay: calc(var(--animation-duration) / 10 * -2);
  }

  .spinner-item:nth-child(4) {
    background: rgba(map-get($main-colors, 'light'), 0.6);
    animation-delay: calc(var(--animation-duration) / 10 * -1);
  }

  .spinner-item:nth-child(5) {
    background: map-get($main-colors, "danger");
    animation-delay: calc(var(--animation-duration) / 10 * -3);
  }
}