.episode-cover-image {
    filter: brightness(0.5);
    position: relative;

    img {
        height: px-to-rem(215);
    }

    .live-label {
        position: absolute;
        top: px-to-rem(10);
        right: px-to-rem(10);
    }
}

.current-episode-cover-image {
    filter: brightness(1);
}
