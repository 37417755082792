.trailer-placeholder {
  .trailer {
    button {
      display: none;
    }
    &.trailer-fullscreen {

      button {
        display: block;
      }
    }
  }
}
