@import "./../../assets/scss/variables";

.fullscreen-popup {
  position: fixed;
  z-index: 30;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;

  .btn-close {
    position: fixed;
    top: px-to-rem(34);
    left: px-to-rem(34);
  }

  .fullscreen-popup-content {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background: $background-color-dark;
    outline: 0;

    .fullscreen-popup-body {
      position: relative;
      flex: 1 1 auto;
      overflow-y: auto;
    }
  }
}
