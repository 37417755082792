@import "variables";
@import "./mixins/media-breckpoints-mixin";

@mixin global() {
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    font-family: $font-special-elite;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: map-get($text-colors, 'text-light');
    text-align: left;
    background-color: $background-color-dark;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .splide:not(.is-overflow) .splide__arrows {
    display: none;
  }
}
