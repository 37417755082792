@import "src/assets/scss/index";

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  & > div {
    &:first-of-type {
      flex: 1 0 auto;
    }
  }
}

@include all-styles();
