.countdown-video {
  position: relative;

  .premiere-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 11;
  }
}
