@import "./../../../assets/scss/variables";
@import "./../../../assets/scss/mixins/media-breckpoints-mixin";

footer {
    margin: px-to-rem(50);

    @include media-breakpoint-up(md) {
        margin: px-to-rem(75);
    }

    @include media-breakpoint-up(xxl) {
        margin: px-to-rem(100);
    }

    .buy-merch {
        width: px-to-rem(160);
        height: px-to-rem(60);
        margin: 0 auto;
        background-image: url("../../../../public/images/footer-papers/merch-paper-bg.png");
        background-image: image-set(url("../../../../public/images/footer-papers/merch-paper-bg.webp") 1x);
        background-size: 100% 100%;
        color: #000;
        text-transform: uppercase;
        font-size: px-to-rem(20);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: px-to-rem(10);
        box-sizing: border-box;
        margin-bottom: px-to-rem(5);

        @include media-breakpoint-up(lg) {
            width: px-to-rem(180);
            height: px-to-rem(70);
            font-size: px-to-rem(24);
        }

        @include media-breakpoint-up(xxl) {
            width: px-to-rem(240);
            height: px-to-rem(90);
            font-size: px-to-rem(28);
            margin-bottom: px-to-rem(16);
        }
    }

    .social-networks {
        display: flex;
        align-items: center;
        justify-content: center;

        .paper {
            position: relative;
            max-width: px-to-rem(75);
            max-height: px-to-rem(75);
            margin: px-to-rem(5);

            @include media-breakpoint-up(lg) {
                margin: px-to-rem(16);
            }

            @include media-breakpoint-up(xxl) {
                max-width: px-to-rem(100);
                max-height: px-to-rem(100);
            }

            &.center {
                background-image: url("../../../../public/images/footer-papers/center.png");
                background-size: 100% 100%;
                width: px-to-rem(75);
                height: px-to-rem(75);
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-up(xxl) {
                    width: px-to-rem(100);
                    height: px-to-rem(100);
                }
            }
        }

        .network-icon {
            max-width: px-to-rem(75);
            max-height: px-to-rem(75);
            transform: scale(.5);
            margin-top: -10px;
            margin-left: 10px;

            @include media-breakpoint-up(xxl) {
                max-width: px-to-rem(100);
                max-height: px-to-rem(100);
            }
        }
    }
}
