@import "./../../assets/scss/variables";
@import "./../../assets/scss/mixins/buttons-mixin";
@import "./../../assets/scss/mixins/media-breckpoints-mixin";

.episode-card {
  display: block;
  color: map-get($text-colors, 'text-dark');

  .episode-card-background {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../../public/images/cards-backgrounds/first-card-background.png");
    background-image: image-set(url("../../../public/images/cards-backgrounds/first-card-background.webp") 1x);
  }

  .episode-card-description {
    text-align: center;
    color:  map-get($text-colors, 'text-dark');

    .episode-cover-picture-wrapper {
      padding: px-to-rem(40) px-to-rem(30) px-to-rem(30);

      @include media-breakpoint-down(xl) {
        padding: px-to-rem(32) px-to-rem(24) px-to-rem(24);
      }

      @include media-breakpoint-down(sm) {
        padding: px-to-rem(24) px-to-rem(16) px-to-rem(16);
      }

      .episode-cover-picture {
        width: 100%;
      }
    }

    .episode-card-title {
      text-transform: uppercase;
      margin: px-to-rem(30) 0 px-to-rem(20) 0;
      font-size: px-to-rem(20);

      @include media-breakpoint-up(lg) {
        font-size: px-to-rem(28);
      }
    }

    ul {
      padding: 0 px-to-rem(10);

      li {
        text-transform: uppercase;
        margin-bottom: px-to-rem(10);
        font-size: px-to-rem(14);

        @include media-breakpoint-up(lg) {
          font-size: px-to-rem(18);
        }
      }
    }

    @include button('btn-link-dark') {
      margin-bottom: px-to-rem(5);
      margin-top: px-to-rem(10);
      font-size: px-to-rem(20);

      @include media-breakpoint-up(lg) {
        font-size: px-to-rem(28);
      }
    }
  }
}
