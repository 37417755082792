@import "./../../../../assets/scss/variables";
@import "./../../../../assets/scss/mixins/media-breckpoints-mixin";
@import "./../../../../assets/scss/mixins/pin-for-paper-mixin";

.tickets-list-container {
  display: flex;
  flex-direction: column;
  flex: 1.5;
  margin-top: px-to-rem(50);

  @include media-breakpoint-up(lg) {
    margin-top: px-to-rem(60);
  }

  @include media-breakpoint-up(xl) {
    margin-top: px-to-rem(60);
  }

  @include media-breakpoint-up(xxl) {
    margin-top: px-to-rem(70);
  }

  .tickets-list-background {
    background-image: url("../../../../../public/images/activate-ticket-popup/buy-right-paper.png");
    background-image: image-set(
      url("../../../../../public/images/activate-ticket-popup/buy-right-paper.webp")
        1x
    );
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: px-to-rem(9);
    min-height: 30%;

    @include media-breakpoint-up(sm) {
      padding-left: px-to-rem(12);
    }

    @include media-breakpoint-up(xl) {
      padding-left: px-to-rem(17);
    }

    @include pin-for-paper();

    .tickets-list-content {
      padding: px-to-rem(20) px-to-rem(20);
      color: map-get($text-colors, "text-dark");
      margin-top: px-to-rem(30);

      @include media-breakpoint-up(lg) {
        padding: px-to-rem(30) px-to-rem(40);
        margin-top: px-to-rem(40);
      }

      @include media-breakpoint-up(xl) {
        padding: px-to-rem(40) px-to-rem(50);
        margin-top: px-to-rem(50);
      }

      @include media-breakpoint-up(xxl) {
        padding: px-to-rem(40) px-to-rem(50);
        margin-top: px-to-rem(50);
      }

      .tickets-list-title {
        font-size: px-to-rem(30);
        margin: px-to-rem(20) 0;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          font-size: px-to-rem(40);
        }

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(50);
        }

        @include media-breakpoint-up(xxl) {
          font-size: px-to-rem(50);
        }
      }

      .tickets-list-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: px-to-rem(20) 0;

        @include media-breakpoint-up(lg) {
          margin: px-to-rem(22) 0;
        }

        @include media-breakpoint-up(xl) {
          margin: px-to-rem(28) 0;
        }

        @include media-breakpoint-up(xxl) {
          margin: px-to-rem(28) 0;
        }
      }

      .tickets-list-box-purchase {
        margin-bottom: px-to-rem(40);

        @include media-breakpoint-up(lg) {
          margin-bottom: px-to-rem(50);
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: px-to-rem(60);
        }

        @include media-breakpoint-up(xxl) {
          margin-bottom: px-to-rem(80);
        }
      }

      .tickets-list-name {
        font-size: px-to-rem(16);
        text-transform: uppercase;
        padding-right: px-to-rem(20);

        @include media-breakpoint-up(md) {
          font-size: px-to-rem(20);
        }

        @include media-breakpoint-up(lg) {
          font-size: px-to-rem(24);
        }

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(28);
        }

        @include media-breakpoint-up(xxl) {
          font-size: px-to-rem(28);
        }
      }

      .tickets-list-price {
        font-size: px-to-rem(16);
        text-transform: uppercase;
        color: map-get($text-colors, "text-danger");

        @include media-breakpoint-up(md) {
          font-size: px-to-rem(20);
        }

        @include media-breakpoint-up(lg) {
          font-size: px-to-rem(24);
        }

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(28);
        }

        @include media-breakpoint-up(xxl) {
          font-size: px-to-rem(28);
        }
      }

      .tickets-list-subtitle {
        font-size: px-to-rem(14);

        @include media-breakpoint-up(md) {
          font-size: px-to-rem(16);
        }

        @include media-breakpoint-up(lg) {
          font-size: px-to-rem(18);
        }
      }
    }
  }
}
