@import "./../../assets/scss/variables";
@import "./../../assets/scss/mixins/media-breckpoints-mixin";
@import "./../../assets/scss/mixins/backrounds-mixin";
@import "./../../assets/scss/mixins/home-page-margins-mixin";
@import "./../../assets/scss/mixins/splide-arrows-paper-style-mixin";
@import "./../../assets/scss/mixins/box-shadow-mixin";

@mixin trailer-padding {
  $landscape-trailer-aspect-ratio: 42.25%;
  $portrait-trailer-aspect-ratio: 126.25%;

  padding-bottom: $landscape-trailer-aspect-ratio;

  @media only screen and (orientation: portrait) {
    padding-bottom: $portrait-trailer-aspect-ratio;
  }
}

.home-page {
  @include background-texture();

  .trailer-container {
    position: relative;
    margin-bottom: px-to-rem(50);
    margin-top: px-to-rem(-96);
    width: 100%;
    background: #000;

    @include trailer-padding();

    .trailer-placeholder {
      position: absolute;
      width: 100%;
    }

    @include box-shadow(0, 0, px-to-rem(50), px-to-rem(25), #000);

    .trailer-skeleton {
      display: none; // temporary disable placeholder image due to low quality
      background-size: cover;

      position: absolute;
      width: 100%;

      @include trailer-padding();

      $trailer-poster-500-png: url("https://dev-video.foulplay.live/assets/public/trailer/season-trailer-thumbnail-resize-500-0.png");
      $trailer-poster-500-webp: url("https://dev-video.foulplay.live/assets/public/trailer/season-trailer-thumbnail-cwebp-resize-500-0.webp");

      $trailer-poster-500-png-portrait: url("https://dev-video.foulplay.live/assets/public/trailer/season-trailer-thumbnail-portrait-resize-500-0.png");
      $trailer-poster-500-webp-portrait: url("https://dev-video.foulplay.live/assets/public/trailer/season-trailer-thumbnail-portrait-cwebp-resize-500-0.webp");

      background-image: $trailer-poster-500-png;
      background-image: image-set($trailer-poster-500-webp 1x);

      @media only screen and (orientation: portrait) {
        background-image: $trailer-poster-500-png-portrait;
        background-image: image-set($trailer-poster-500-webp-portrait 1x);
      }
    }

    .trailer-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;

      &.hidden {
        display: none;
      }
    }

    .video-js {
      width: 100%;
      height: auto;
      position: relative;

      @include trailer-padding();

      video {
        position: absolute;
        z-index: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .episode-cards-container {
    margin-bottom: px-to-rem(50);

    $first-paper-png: "/../public/images/splide-papers/first-paper.png";
    $first-paper-webp: "/../public/images/splide-papers/first-paper.webp";
    $second-paper-png: "/../public/images/splide-papers/second-paper.png";
    $second-paper-webp: "/../public/images/splide-papers/second-paper.webp";

    @include paper-arrows($first-paper-png, $first-paper-webp, $second-paper-png, $second-paper-webp);
  }

  .cast-members {
    margin-bottom: px-to-rem(50);

    $first-paper-png: "/../public/images/splide-papers/third-paper.png";
    $first-paper-webp: "/../public/images/splide-papers/third-paper.webp";
    $second-paper-png: "/../public/images/splide-papers/fourth-paper.png";
    $second-paper-webp: "/../public/images/splide-papers/fourth-paper.webp";

    @include paper-arrows($first-paper-png, $first-paper-webp, $second-paper-png, $second-paper-webp) {
      .splide__arrows {
        margin-top: px-to-rem(20);
        margin-right: px-to-rem(24);
      }
    };

    .cast-members-title-box {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-image: url("../../../public/images/title-papers/our-suspects-title.png");
      background-image: image-set(url("../../../public/images/title-papers/our-suspects-title.webp") 1x);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      min-width: px-to-rem(268);
      min-height: px-to-rem(114);
      margin-bottom: px-to-rem(30);
      @include home-page-margins();

      @include media-breakpoint-up(lg) {
        min-width: px-to-rem(310);
        min-height: px-to-rem(145);
      }

      .cast-members-title {
        font-size: px-to-rem(20);
        color: map-get($text-colors, 'text-dark');
        text-transform: uppercase;
        padding-top: px-to-rem(10);

        @include media-breakpoint-up(lg) {
          font-size: px-to-rem(28);
        }
      }
    }
  }
}