@import "./../../../../../assets/scss/variables";

.evidence-popup {
  &.fullscreen-popup-content {
    backdrop-filter: blur(50px);
    background: unset;

    .fullscreen-popup-body {
      margin: px-to-rem(65) 0;

      .evidence-slider {
        height: 100%;

        .splide__track {
          height: 100%;

          .splide__list {
            height: 100%;
            align-items: center;

            .splide__slide {
              transition: 0.2s;

              &:not(.is-active) {
                transform: scale(0.8);

                &:after {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 10;
                  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
                }
              }
            }
          }
        }
      }
    }
  }
}
