@import "./../../../../assets/scss/mixins/media-breckpoints-mixin";
@import "./../../../../assets/scss/functions/functions";

.popup-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include media-breakpoint-down(sm) {
    .btn-link {
      padding-left: 0;
      padding-right: 0;
      font-size: px-to-rem(16);
    }
  }

  .btn {
    margin: px-to-rem(24) px-to-rem(8);
  }
}
