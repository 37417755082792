.hls-video-player {
  height: 100%;

  & > div,
  .video-js {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .vjs-big-play-button {
      left: unset;
      top: unset;
    }
  }
}
