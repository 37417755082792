@import "./../variables";
@import "./../mixins/media-breckpoints-mixin";

@mixin background-texture() {
  background-repeat: no-repeat;
  background-size: cover;

  @each $size, $image-name in $backgrounds-textures {
    $path: "../../../../public/images/main-page-backgrounds/";

    @if $size == 0 {
      background-image: url('#{$path}#{$image-name}.png');
      background-image: image-set(url("#{$path}#{$image-name}.webp") 1x);
    } @else {
      @include media-breakpoint-up($size) {
        background-image: url("#{$path}/#{$image-name}.png");
        background-image: image-set(url("#{$path}#{$image-name}.webp") 1x);
      }
    }
  }
}

@mixin fixed-background() {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
