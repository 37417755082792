@import "../variables";
@import "../functions/functions";

@mixin button($button-name: 'default') {
  .btn {
    position: relative;
    display: inline-block;
    background-color: transparent;
    text-transform: uppercase;
    box-sizing: border-box;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: px-to-rem(20);
    line-height: px-to-rem(20);
    border-radius: unset;
    border: px-to-rem(2) solid transparent;
    color: map-get($main-colors, 'light');
    padding: px-to-rem(12) px-to-rem(16);
    transition: 0.3s;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    z-index: 1;

    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;

      &:before {
        display: none;
      }

      &:hover {
        color: currentColor;
      }
    }

    @content;

    @each $key, $keys in $buttons-set {
      &.#{$key} {
        color: map-complex-get($buttons-set, $key, color);
        padding: map-complex-get($buttons-set, $key, padding);
        background-color: map-complex-get($buttons-set, $key, backround);
        border: map-complex-get($buttons-set, $key, border);
        width: map-complex-get($buttons-set, $key, width);
        height: map-complex-get($buttons-set, $key, height);
        background-image: map-complex-get($buttons-set, $key, background-image);

        @if $key != 'btn-link'
        and $key != 'btn-link-dark'
        and $key != 'btn-live'
        and $key != 'btn-close'
        and $key != 'btn-burger'
        and $key != 'btn-icon'
        and $key != 'btn-full-live' {
          &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: map-complex-get($buttons-set, $key, backround);
            transition: 0.3s
          }

          &:hover {
            pointer-events: map-complex-get($buttons-set, $key, no-hoder);
            text-shadow: 0 0 px-to-rem(5.90625) rgba(map-get($main-colors, 'light'), 0.45);
            backdrop-filter: blur(px-to-rem(14));
            background: linear-gradient(180deg, map-get($main-colors, 'light') 0%, map-get($main-colors, 'light') 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            transition: 0.3s;
            border-color: rgba(map-get($main-colors, 'light'), 0.2);

            &::before {
              background: rgba(map-get($main-colors, 'light'), 0.2);
            }
          }

          &:focus {
            background: linear-gradient(180deg,  rgba(map-get($main-colors, 'light'), 0.1) 0%, rgba(map-get($main-colors, 'light'), 0.1) 100%), map-get($main-colors, 'light');
            backdrop-filter: blur(px-to-rem(14));
            background-blend-mode: overlay, normal;
            color: map-get($main-colors, 'dark');
            text-shadow: 0 0 px-to-rem(5.90625) rgba(map-get($main-colors, 'dark'), 0.45);
            -webkit-text-fill-color: unset;
          }
        }

        @if $key == 'btn-outline-dark' {
          &:hover {
            text-shadow: map-complex-get($buttons-set, $key, hover-text-shadow);
            color: map-complex-get($buttons-set, $key, hover-color);
            background: map-complex-get($buttons-set, $key, hover-bg);
            border-color: map-complex-get($buttons-set, $key, hover-border-color);
            -webkit-background-clip: text;

            &::before {
              background-color: map-complex-get($buttons-set, $key, hover-before-bg);
            }
          }

          &:focus {
            background: map-complex-get($buttons-set, $key, focus-bg);
            color: map-complex-get($buttons-set, $key, focus-color);
            text-shadow: map-complex-get($buttons-set, $key, focus-text-shadow);
          }
        }

        @if $key == 'btn-live' {
          display: flex;
          align-items: center;
          border: none;

          &:after {
            content: "";
            position: absolute;
            left: px-to-rem(-4);
            width: px-to-rem(8);
            height: px-to-rem(8);
            top: px-to-rem(19);
            background-color: map-get($main-colors, 'danger');
            border-radius: 50%;
            z-index: 1;
          }
        }

        @if $key == 'btn-full-live' {
          cursor: inherit;

          &:after {
            content: "";
            position: absolute;
            right: px-to-rem(16);
            width: px-to-rem(8);
            height: px-to-rem(8);
            top: px-to-rem(16);
            background-color: map-get($main-colors, 'danger');
            border-radius: 50%;
            z-index: 1;
            animation: pulse 3s infinite;
          }

          @keyframes pulse {
            0% {
              background-color: rgba(map-get($main-colors, 'danger'), 0);
            }

            10% {
              background-color: rgba(map-get($main-colors, 'danger'), 0.2);
            }

            20% {
              background-color: rgba(map-get($main-colors, 'danger'), 0.4);
            }

            30% {
              background-color: rgba(map-get($main-colors, 'danger'), 0.6);
            }

            40% {
              background-color: rgba(map-get($main-colors, 'danger'), 0.8);
            }

            50% {
              background-color: rgba(map-get($main-colors, 'danger'), 1);
            }

            60% {
              background-color: rgba(map-get($main-colors, 'danger'), 0.8);
            }

            70% {
              background-color: rgba(map-get($main-colors, 'danger'), 0.6);
            }

            80% {
              background-color: rgba(map-get($main-colors, 'danger'), 0.4);
            }

            90% {
              background-color: rgba(map-get($main-colors, 'danger'), 0.2);
            }

            100% {
              background-color: rgba(map-get($main-colors, 'danger'), 0.1);
            }
          }
        }

        @if $key == 'btn-close' or $key == 'btn-burger' {
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          z-index: 10;
          @content;
        }
      }
    }
  }
}
