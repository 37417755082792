@import "./functions/functions";

$font-special-elite: 'Special Elite', cursive;

$main-colors: (
  "dark": #000,
  "light": #fff,
  "primary": #4747C6,
  "danger": #d3273e,
);

$text-colors: (
  "text-dark": map-get($main-colors, 'dark'),
  "text-light": map-get($main-colors, 'light'),
  "text-danger": map-get($main-colors, 'danger'),
);

$border-colors: (
  "border-color-dark": map-get($main-colors, 'dark'),
  "border-color-light": map-get($main-colors, 'light'),
);

$background-color-dark: map-get($main-colors, 'dark');

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$z-index-positions: (
  z-index-main-logo: 10,
);

$buttons-set: (
  btn-link: (
    color: rgba(map-get($main-colors, 'light'), 0.7),
    padding: px-to-rem(12) px-to-rem(10),
  ),
  btn-link-dark: (
    color: map-get($main-colors, 'dark'),
    padding: px-to-rem(12) 0,
    no-hoder: none,
  ),
  btn-primary: (
    color: rgba(map-get($main-colors, 'light'), 0.8),
    backround: map-get($main-colors, 'primary'),
    border: px-to-rem(2) solid map-get($main-colors, 'primary'),

  ),
  btn-danger: (
    color: rgba(map-get($main-colors, 'light'), 0.8),
    backround: map-get($main-colors, 'danger'),
    border: px-to-rem(2) solid map-get($main-colors, 'danger'),

  ),
  btn-light: (
    color: map-get($main-colors, 'dark'),
    backround: map-get($main-colors, 'light'),
    border: px-to-rem(2) solid map-get($main-colors, 'light'),
  ),
  btn-outline-secondary: (
    backround: rgba(map-get($main-colors, 'light'), 0.1),
    color: rgba(map-get($main-colors, 'light'), 0.8),
    border: px-to-rem(2) solid rgba(map-get($main-colors, 'light'), 0.2),
  ),
  btn-outline-dark: (
    border: 2px solid rgba(map-get($main-colors, 'dark'), 0.2),
    color: rgba(map-get($main-colors, 'dark'), 0.8),
    hover-bg: linear-gradient(180deg, map-get($main-colors, 'dark') 0%, map-get($main-colors, 'dark') 100%),
    hover-before-bg: rgba(map-get($main-colors, 'dark'), 0.2),
    hover-border-color: rgba(map-get($main-colors, 'dark'),  0.2),
    hover-text-shadow: 0 0 px-to-rem(5.90625) rgba(map-get($main-colors, 'dark'), 0.45),
    hover-color: map-get($main-colors, 'dark'),
    focus-bg: map-get($main-colors, 'dark'),
    focus-color: map-get($main-colors, 'light'),
    focus-text-shadow: 0 0 px-to-rem(5.90625) rgba(map-get($main-colors, 'light'), 0.45),
  ),
  btn-icon: (
    padding: px-to-rem(8),
    backround: rgba($background-color-dark, 0.22),
    width: px-to-rem(46),
    height: px-to-rem(46),
  ),
  btn-close: (
    width: px-to-rem(20),
    height: px-to-rem(20),
    background-image: url("/../public/images/icons/close-icon.svg"),
  ),
  btn-burger: (
    width: px-to-rem(24),
    height: px-to-rem(24),
    background-image: url("/../public/images/icons/burger-menu-icon.svg"),
  ),
  btn-live: (),
  btn-full-live: (
    color: map-get($main-colors, 'dark'),
    backround: map-get($main-colors, 'light'),
    padding: px-to-rem(12) px-to-rem(34) px-to-rem(12) px-to-rem(16),
  ),
);

$episode-card-background-path: "../../../public/images/cards-backgrounds/";
$episode-card-backgrounds: (
  1: (
    background: "first-card-background",
    padding: 0 1% 12% 10%,
    episode-card-margin: 0 -1% -5% -10%,
  ),
  2: (
    background: "second-card-background",
    transform: translateY(-10px),
    padding: 0 7% 22% 8%,
    episode-card-margin: 0 -7% -8% -8%,
    title-margin-top: 15%,
  ),
  3: (
    background: "third-card-background",
    padding: 0 2% 20% 9%,
    episode-card-margin: 0 -2% -16% -9%,
  ),
  4: (
    background: "fourth-card-background",
    padding: 0 0 10% 0,
    episode-card-margin: 0 0 -10% 0,
    title-margin-top: 7%,
  ),
  5: (
    background: "fifth-card-background",
    padding: 0 1% 8% 0%,
    episode-card-margin: 0 -1% -8%  0%,
  ),
);

$backgrounds-textures: (
  0:   'main-bg-576',
  md:  'main-bg-768',
  lg:  'main-bg-992',
  xl:  'main-bg-1200',
  xxl: 'main-bg-1400',
);

$cast-member-card-transform: (
  1: rotate(0deg) ,
  2: rotate(1.82deg),
  3: rotate(-1.52deg),
  4: rotate(-0.06deg),
  5: rotate(1.82deg),
  6: rotate(-1.89deg),
  7: rotate(0.72deg),
  8: rotate(2.32deg),
  9: rotate(-1.89deg),
  10: rotate(-0.07deg),
);

$player-control-bar-colors: (
  "play-progress": #FF3636,
  "load-progress": #D9D9D9,
  "progress-background": #73859F,
  "custom-button-background": #2B333F,
);
