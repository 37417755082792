@import "../variables";

@mixin videojs-custom-player() {
  .video-js {

    // Control Bar
    .vjs-control-bar {
      background-color: map-get($main-colors, 'dark');
    }

    // Progress Control
    .vjs-play-progress {
      color: map-get($player-control-bar-colors, 'play-progress');
      background-color: map-get($player-control-bar-colors, 'play-progress');
    }

    .vjs-loading-spinner {
      display: none;
    }

    .vjs-progress-control {
      width: 100%;
      margin-top: px-to-rem(12);
    }

    .vjs-slider {
      background: rgba(map-get($player-control-bar-colors, 'progress-background'), 0.75);
    }

    .vjs-load-progress div {
      background: map-get($player-control-bar-colors, 'load-progress');
    }

    .vjs-current-time {
      display: block; 
    }

    .vjs-duration {
      display: block; 
    }

    .vjs-volume-tooltip {
      margin-top: px-to-rem(5);
    }

    .vjs-time-control {
      font-size: px-to-rem(12);
      line-height: px-to-rem(15);
      padding: 0;
      min-width: px-to-rem(5);
      margin: px-to-rem(10) px-to-rem(5);
    }

    .vjs-time-divider {
      display: block; 
      min-width: px-to-rem(5);
      margin: px-to-rem(10) 0;
    }

    .vjs-control-bar {
      flex-wrap: wrap;
      justify-content: flex-start;
      height: auto;
    }

    // Custom Loading Spinner
    .vjs-player-spinner {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: px-to-rem(-35) 0 0 px-to-rem(-35);
    }

    // Custom Player Buttons
    .vjs-big-unmute-button, 
    .vjs-big-custom-play-button {
      position: absolute;
      height: px-to-rem(45);
      width: px-to-rem(90);
      background-color: rgba(map-get($player-control-bar-colors, 'progress-background'), 0.7);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      border: 1px solid map-get($main-colors, 'light');
      transform: translate(-50%, -50%);
      border-radius: 9px;
      cursor: pointer;
    }

    .vjs-big-unmute-button {
      .vjs-icon-placeholder {
        content: url("/../public/images/icons/unmute-icon.svg");
      }
    }
    
    .vjs-big-custom-play-button {
      .vjs-icon-placeholder {
        content: url("/../public/images/icons/play-icon.svg");
      }
    }
  }

  .video-js:hover .vjs-big-play-button, 
  .video-js:hover .vjs-big-unmute-button, 
  .video-js:hover .vjs-big-custom-play-button, 
  .video-js .vjs-big-play-button:focus {
    background-color: rgba(map-get($main-colors, 'danger'), 0.7);
  }

  .video-js.vjs-seeking .vjs-player-spinner, 
  .video-js.vjs-waiting .vjs-player-spinner {
    display: block;
  }

}
