@import "./../../assets/scss/variables";
@import "./../../assets/scss/mixins/media-breckpoints-mixin";

.episode-watch-top-menu {
  padding: px-to-rem(45) px-to-rem(40) 0 px-to-rem(40);
  display: flex;
  justify-content: space-between;

  &-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }

    .btn {
      margin-right: px-to-rem(16);
      margin-bottom: px-to-rem(10);
    }

    .btn-discord {
      display: inline-flex;
      align-items: center;

      &:focus {
        .icon {
          filter: invert(1);
        }
      }

      .title {
        display: none;

        @include media-breakpoint-up(md) {
          display: inline-block;
        }
      }

      .icon {
        margin-top: px-to-rem(-5);
        margin-left: 0;
        min-width: px-to-rem(24);

        @include media-breakpoint-up(md) {
          margin-left: px-to-rem(8);
        }
      }
    }
  }
}
