@import "./../../../assets/scss/variables";


$default-paper-png: "/../public/images/splide-papers/first-paper.png";
$default-paper-webp: "/../public/images/splide-papers/first-paper.webp";
$default-second-paper-png: "/../public/images/splide-papers/second-paper.png";
$default-second-paper-webp: "/../public/images/splide-papers/second-paper.webp";

@mixin paper-arrows(
  $first-paper-png: $default-paper-png,
  $first-paper-webp: $default-paper-webp,
  $second-paper-png: $default-second-paper-png,
  $second-paper-webp: $default-second-paper-webp
) {
  .splide {
    display: flex;
    flex-direction: column-reverse;

    .splide__arrows {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .splide__arrow {
        position: relative;
        left: unset;
        top: unset;
        transform: unset;
        right: unset;
        background: unset;
        opacity: unset;
        background-size: 100% 100%;
        width: px-to-rem(100);
        height: px-to-rem(67);
        border-radius: unset;
        margin: 0 px-to-rem(10);

        &[disabled] {
          &:before {
            opacity: 0.3;
          }
        }

        &:before {
          content: "";
          background-image: url("/../public/images/splide-papers/splide-arrow.png");
          background-image: image-set(
              url("/../public/images/splide-papers/splide-arrow.webp")
              1x
          );
          width: px-to-rem(60);
          height: px-to-rem(34);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        svg {
          display: none;
        }

        &.splide__arrow--prev {
          background-image: url($first-paper-png);
          background-image: image-set(url($first-paper-webp) 1x);

          &:before {
            transform: rotate(180deg);
          }
        }

        &.splide__arrow--next {
          background-image: url($second-paper-png);
          background-image: image-set(url($second-paper-webp) 1x);
        }
      }
    }
    @content
  }
}
