.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-form {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.login-form label {
  display: flex;
  justify-content: space-between;
}

.login-form label span {
  margin-right: 10px;
}
