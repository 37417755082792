@import "./../../assets/scss/mixins/buttons-mixin";
@import "./../../assets/scss/mixins/media-breckpoints-mixin";

.episode-info {
  flex: 1;
  display: flex;

  .episode-info-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .episode-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 50%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 1.24%, #000000 83.46%);
    }

    .episode-info-top-container {
      margin: px-to-rem(15) px-to-rem(24) 0 px-to-rem(20);

      @include media-breakpoint-up(md) {
        margin: px-to-rem(15) px-to-rem(24) 0 px-to-rem(40);
      }

      @include media-breakpoint-up(lg) {
        margin: px-to-rem(15) px-to-rem(24) 0 px-to-rem(80);
      }

      .trailer-placeholder {
        .video-js {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -2;

          video {
            position: absolute;
            z-index: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .episode-info-logo {
        display: block;
        margin-bottom: px-to-rem(10);
        max-width: px-to-rem(350);
      }

      .episode-info-buttons-box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .btn {
          margin-right: px-to-rem(20);
          margin-bottom: px-to-rem(10);
        }

        .episode-info-premiere-wrapper {
          display: flex;
          flex-direction: column;

          .episode-info-premiere-title {
            color: rgba(map-get($main-colors, 'light'), 0.4);
          }

          .episode-info-premiere-date {
            text-transform: uppercase;
          }

          p {
            margin: 0;
          }
        }
      }
    }

    .episode-info-bottom-container {

      .episode-info-bottom-wrapper {
        margin: px-to-rem(15) px-to-rem(24) px-to-rem(24) px-to-rem(20);

        @include media-breakpoint-up(md) {
          margin-left: px-to-rem(40);
        }

        @include media-breakpoint-up(lg) {
          margin-left: px-to-rem(80);
        }

        .episode-info-cast-members {
          .splide__list {
            .splide__slide {
              @each $number, $transform-value in $cast-member-card-transform {
                &:nth-child(#{$number}) {
                  transform: $transform-value;
                }
              }
            }
          }
        }

        .episode-info-description {
          width: 100%;
          padding: 0 px-to-rem(10) 0 0;
          margin: px-to-rem(10) 0;
          letter-spacing: px-to-rem(1);

          @include media-breakpoint-up(lg) {
            width: px-to-rem(750);
          }
        }
      }

      .episode-info-cover-slider {
        margin-bottom: px-to-rem(20);

        @include media-breakpoint-up(lg) {
          margin-bottom: px-to-rem(40);
        }
      }
    }
  }
}