@import "./../../../../assets/scss/mixins/media-breckpoints-mixin";
@import "./../../../../assets/scss/functions/functions";

.nav-links-container {
  .btn-close {
    display: none;
  }

  .btn-link {
    margin: px-to-rem(24) px-to-rem(8);
  }

  @include media-breakpoint-down(lg) {
    right: 0;
    height: 100%;
    z-index: 40;
    top: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    transition: 0.3s;
    background: $background-color-dark;
    width: px-to-rem(300);
    transform: translateX(100%);

    &.opened {
      transform: translateX(0);

      &+.navigation-menu-overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    .btn-link {
      padding: px-to-rem(17) px-to-rem(10) px-to-rem(17) px-to-rem(20);
      border-bottom: px-to-rem(1) solid gray;
      margin: 0;


      &:first-of-type {
        border-top: px-to-rem(1) solid gray;
        margin-top: px-to-rem(95);
      }
    }

    .btn-close {
      display: block;
      position: absolute;
      margin: px-to-rem(37) px-to-rem(10) px-to-rem(17) px-to-rem(20);
      right: 0;
    }

    .btn-burger {
      display: block;
    }
  }
}