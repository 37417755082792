@import "./../../../../assets/scss/variables";
@import "./../../../../assets/scss/mixins/buttons-mixin";
@import "./../../../../assets/scss/mixins/placeholder-mixin";
@import "./../../../../assets/scss/mixins/media-breckpoints-mixin";
@import "./../../../../assets/scss/mixins/pin-for-paper-mixin";
@import "./../../../../assets/scss/mixins/box-shadow-mixin";

.activate-ticket-form-container {
  display: flex;
  flex-direction: column;
  flex: .8;
  padding: px-to-rem(20) 0 0 px-to-rem(30);

  @include media-breakpoint-up(lg) {
    padding: px-to-rem(20) 0 0 px-to-rem(30);
  }

  @include media-breakpoint-up(xl) {
    padding: px-to-rem(20) 0 0 px-to-rem(80);
  }

  @include media-breakpoint-up(xxl) {
    padding: px-to-rem(20) 0 0 px-to-rem(100);
  }

  .activate-ticket-form-background {
    background-image: url("../../../../../public/images/activate-ticket-popup/buy-right-paper.png");
    background-image: image-set(
      url("../../../../../public/images/activate-ticket-popup/buy-right-paper.webp")
        1x
    );
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: px-to-rem(9);
    min-height: 30%;

    @include media-breakpoint-up(sm) {
      padding-left: px-to-rem(12);
    }

    @include media-breakpoint-up(lg) {
      padding-left: px-to-rem(11);
    }

    @include media-breakpoint-up(xl) {
      padding-left: px-to-rem(16);
    }

    @include pin-for-paper();

    .activate-ticket-form-content {
      padding: px-to-rem(50) px-to-rem(20) px-to-rem(40) px-to-rem(20);
      text-align: center;
      margin: 0 auto;
      color: map-get($text-colors, "text-dark");

      @include media-breakpoint-up(lg) {
        padding: px-to-rem(60) px-to-rem(30) px-to-rem(50) px-to-rem(30);
      }

      @include media-breakpoint-up(xl) {
        padding: px-to-rem(80) px-to-rem(50) px-to-rem(60) px-to-rem(50);
      }

      @include media-breakpoint-up(xxl) {
        padding: px-to-rem(100) px-to-rem(70) px-to-rem(70) px-to-rem(70);
      }

      .activate-ticket-form-image-box {
        position: relative;
        margin: px-to-rem(-10);

        @include media-breakpoint-up(lg) {
          margin: px-to-rem(-20);
        }

        @include media-breakpoint-up(xl) {
          margin: px-to-rem(-30);
        }

        @include media-breakpoint-up(xxl) {
          margin: px-to-rem(-40px);
        }
      }

      .activate-ticket-form-title {
        margin: px-to-rem(20) 0 px-to-rem(10) 0;
        font-size: px-to-rem(30);
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          margin: px-to-rem(30) 0 px-to-rem(20) 0;
          font-size: px-to-rem(30);
        }

        @include media-breakpoint-up(xl) {
          margin: px-to-rem(40) 0 px-to-rem(30) 0;
          font-size: px-to-rem(40);
        }

        @include media-breakpoint-up(xxl) {
          margin: px-to-rem(50) 0 px-to-rem(40) 0;
          font-size: px-to-rem(50);
        }
      }

      .activate-ticket-form-label {
        margin-bottom: px-to-rem(10);
      }

      .activate-ticket-form-input {
        padding: px-to-rem(16) px-to-rem(10);
        background: #6c6f678f;
        border: 1px solid #595b4e70;
        @include box-shadow(px-to-rem(2), px-to-rem(2), px-to-rem(5), 0, #0000004f, inset);
        margin-bottom: px-to-rem(20);
        outline: navajowhite;
        width: -webkit-fill-available;

        @include media-breakpoint-up(sm) {
          width: inherit;
        }
      }

      @include placeholder {
        color: rgba(map-get($text-colors, "text-dark"), 0.7);
      }
    }
  }
}
