@import './../../assets/scss/variables';

.simple-popup {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 20;
  height: 65%;
  width: 100%;
  background: $background-color-dark;
  border: 1px solid map-get($main-colors, 'border-color-light');
  top: 20%;
  bottom: 0;
  right: 0;
  padding: 2.5rem;
  box-sizing: border-box;

  &-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .btn-close {
    align-self: flex-start;
    width: px-to-rem(20);
    height: px-to-rem(20);
    background-image: url('../../../public/images/icons/close-icon.svg');
    background-repeat: no-repeat;
    padding: 0;
    cursor: pointer;
  }
}
