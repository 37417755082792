@import "./../../assets/scss/mixins/media-breckpoints-mixin";
@import "./../../assets/scss/mixins/buttons-mixin";

.navigation-menu {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  padding-right: px-to-rem(16);
  background-color: $background-color-dark;

  .site-logo {
    max-height: calc(#{px-to-rem(96)} - #{px-to-rem(10)});
    padding-left: px-to-rem(28);
    padding-top: px-to-rem(3);
    z-index: map-get($z-index-positions, 'z-index-main-logo');

    @include media-breakpoint-down(sm) {
      padding-top: px-to-rem(12);
    }

    .site-logo-img {
      max-height: unset;
      min-width: unset;

      @include media-breakpoint-down(sm) {
        max-height: px-to-rem(56);
        min-width: px-to-rem(56);
      }

      @include media-breakpoint-down(lg) {
        max-height: px-to-rem(80);
      }
    }
  }

  .menu-items-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 10;

    .btn-burger {
      display: none;

      @include media-breakpoint-down(lg) {
        display: inline-block;
        margin: px-to-rem(-5) px-to-rem(10) 0 px-to-rem(10);
      }

      @include media-breakpoint-down(sm) {
        margin: px-to-rem(-5) px-to-rem(5) 0 px-to-rem(5);
      }
    }
  }
}