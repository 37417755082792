@import "./../../assets/scss/variables";
@import "./../../assets/scss/mixins/backrounds-mixin";
@import "./../../assets/scss/mixins/pin-for-paper-mixin";
@import "./../../assets/scss/mixins/typography-mixin";

.about-us-page {
  display: flex;
  flex-direction: column;
  @include background-texture();
  @include fixed-background();
  background-attachment: fixed;
  @include pin-for-paper;


  .navigation-menu {
    background-color: transparent;
  }

  .about-us-content {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
    grid-template-areas:
      "article article"
      "header header"
      "main main";
    color: map-get($text-colors, 'text-dark');
    padding: px-to-rem(20) px-to-rem(10);

    @include media-breakpoint-up(md) {
      grid-template-areas:
        "header header"
        "main article";
      padding: px-to-rem(20) px-to-rem(40);
    }

    @include media-breakpoint-up(xxl) {
      grid-template-columns: 70% 30%;
    }

    .pin-box {
      img {
        @include media-breakpoint-down(md) {
          width: px-to-rem(30);
        }
      }
    }

    .about-us-header {
      grid-area: header;
      padding: px-to-rem(10) 0;

      @include media-breakpoint-up(md) {
        padding: 0 px-to-rem(10) px-to-rem(20) 5%;
      }

      .pin-box {
        img {
          margin-left: px-to-rem(-8);
        }
      }
    }

    .about-us-article-content {
      grid-area: main;
      background-image: url("../../../public/images/about-us/main-paper-long.png");
      background-image: image-set(
          url("../../../public/images/about-us/main-paper-long.webp") 1x
      );
      background-size: 100% 100%;

      @include media-breakpoint-up(md) {
        background-image: url("../../../public/images/about-us/main-paper.png");
        background-image: image-set(
            url("../../../public/images/about-us/main-paper.webp") 1x
        );
      }

      .about-us-article {
        position: relative;
        padding: 12% 5% 11% 5%;

        @include article-font-size();

        @include media-breakpoint-up(sm) {
          padding: 11% 5% 15% 5%;
        }

        @include media-breakpoint-up(lg) {
          padding: 9% 5% 13% 5%;
        }

        @include media-breakpoint-up(xl) {
          padding: 8% 5% 5% 5%;
        }

        @include media-breakpoint-up(xxl) {
          padding: 5% 5% 5% 5%;
        }

        .section-title {
          font-weight: 700;
        }
      }
    }

    .about-us-aside {
      position: relative;
      grid-area: article;
      display: flex;
      align-items: center;
      flex-direction: column;

      .about-us-aside-background {
        position: relative;
        background-image: url("../../../public/images/about-us/side-paper.png");
        background-image: image-set(
            url("../../../public/images/about-us/side-paper.webp") 1x
        );
        background-size: 100% 100%;
        padding: 6.3% 4% 11% 9.7%;
        width: 70%;

        @include media-breakpoint-up(sm) {
          width: auto;
          padding: 7.3% 4% 9% 9.7%;
        }

        @include media-breakpoint-up(md) {
          width: auto;
          padding: 7.3% 4% 14% 9.7%;
        }

        @include media-breakpoint-up(lg) {
          padding: 8.3% 4% 13% 9.7%;
        }

        @include media-breakpoint-up(xl) {
          padding: 8.3% 4% 13% 9.7%;
        }

        @include media-breakpoint-up(xxl) {
          padding: 8.3% 4% 8.5% 9.7%;
        }
      }

      .pin-box {
        img {
          margin-top: -6%;

          @include media-breakpoint-up(xxl) {
            margin-top: -9%;
          }
        }
      }

      .about-us-aside-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .about-us-aside-paragraph {
        margin: 0;
      }

      button {
        padding: 0;
        border: 0;
        cursor: pointer;
        background: transparent;
      }
    }
  }
}