@import "./../../assets/scss/variables";
@import "./../../assets/scss/mixins/media-breckpoints-mixin";

.cast-member-card {
  cursor: pointer;
}

.cast-member-popup {
  .fullscreen-popup-body {
    display: flex;

    .popup-overlays,
    .popup-overlay-background,
    .popup-overlay-gradient,
    .popup-overlay-filter-blue {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 0;

      .popup-overlay-background {
        background-image: url("../../../public/images/popups-backgrounds/cast-popup-background.png");
        background-image: image-set(
          url("../../../public/images/popups-backgrounds/cast-popup-background.webp")
            1x
        );
        background-size: contain;
        width: 100%;
        height: 100%;
        filter: blur(px-to-rem(58.3331));
      }

      .popup-overlay-gradient {
        background: linear-gradient(172deg, $background-color-dark 0%, rgba(0, 0, 0, 0) 53.63%);
      }
    }
  }

  .cast-member-popup-content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - #{px-to-rem(25)} * 2);
    padding: px-to-rem(25);
    margin: auto;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      padding: px-to-rem(75) px-to-rem(170);
      height: calc(100vh - #{px-to-rem(75)} * 2);

    }

    img {
      max-height: calc(100% - #{px-to-rem(25)} * 2);
      width: auto;

      @include media-breakpoint-up(lg) {
        max-height: calc(100% - #{px-to-rem(75)} * 2);
      }
    }
  }
}
