@import "./../../assets/scss/variables";
@import "./../../assets/scss/mixins/media-breckpoints-mixin";

.episode-info-page {
  display: flex;
  flex-direction: column;

  .navigation-menu {
    background: none;

    .site-logo {
      max-height: calc(#{px-to-rem(96)} - #{px-to-rem(10)});
      padding-left: px-to-rem(28);
      padding-top: px-to-rem(3);

      @include media-breakpoint-down(sm) {
        padding-top: px-to-rem(12);
      }

      img {
        @include media-breakpoint-down(sm) {
          max-height: px-to-rem(56);
          min-width: px-to-rem(56);
        }

        @include media-breakpoint-down(lg) {
          max-height: px-to-rem(80);
        }
      }
    }
  }
}
