@import "./../../../../assets/scss/variables";

$mute-icon: (
  "dark": url("../../../../../public/images/icons/mute-dark-icon.svg"),
  "light": url("../../../../../public/images/icons/mute-icon.svg"),
);

$unmute-icon: (
  "dark": url("../../../../../public/images/icons/unmute-dark-icon.svg"),
  "light": url("../../../../../public/images/icons/unmute-icon.svg"),
);

.mute {
  img {
    opacity: 1;
  }

  &.btn.btn-outline-secondary {
    &:hover,
    &:focus {
      transition: unset;
      background-repeat: no-repeat;
      background-position: 50% calc(50% - 3px);
      -webkit-background-clip: unset;

      img {
        opacity: 0;
      }
    }

    &:hover {
      &.mute-state {
        background-image: map-get($unmute-icon, 'light');
      }

      &.unmute-state {
        background-image: map-get($mute-icon, 'light');
      }
    }

    &:focus {
      background-blend-mode: unset;

      &.mute-state {
        background-image: map-get($mute-icon, 'dark');
      }

      &.unmute-state {
        background-image: map-get($unmute-icon, 'dark');
      }
    }
    
    &:active {
      &.mute-state {
        background-image: map-get($unmute-icon, 'dark');
      }

      &.unmute-state {
        background-image: map-get($mute-icon, 'dark');
      }
    }
  }
}
