@import "./../../assets/scss/variables";
@import "./../../assets/scss/functions/functions";

.cards-slider {
  @each $key, $keys in $episode-card-backgrounds {
    .splide__slide {
      &:nth-child(#{$key}) {
        .episode-card {
          margin: map-complex-get($episode-card-backgrounds, $key, episode-card-margin);
          transform: map-complex-get($episode-card-backgrounds, $key, transform);
        }
      }

      &:nth-child(#{$key}) {
        .episode-card-background {
          background-image: url("../../../public/images/cards-backgrounds/#{map-complex-get($episode-card-backgrounds, $key, background)}.png");
          background-image: image-set(
            url("../../../public/images/cards-backgrounds/#{map-complex-get($episode-card-backgrounds, $key, background)}.webp")
              1x
          );

          .episode-card-description {
            padding: map-complex-get($episode-card-backgrounds, $key, padding);

            .episode-card-title {
              margin-top: map-complex-get($episode-card-backgrounds, $key, title-margin-top);
            }
          }
        }
      }
    }
  }
}
