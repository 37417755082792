@import "./../../assets/scss/mixins/buttons-mixin";
@import "./../../assets/scss/mixins/media-breckpoints-mixin";

.waiting-room {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;

  .waiting-room-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-bottom: px-to-rem(45);
    margin-top: px-to-rem(10);

    .waiting-room-row {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      .waiting-room-show-premiere {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex: 1 0 calc(75% - #{px-to-rem(1)});
        padding: px-to-rem(0.5);


        .waiting-room-show-premiere-bg {
          position: relative;
          height: 0;
          padding-bottom: 56.25%;
          width: 100%;

          .waiting-room-info-wrapper {
            position: absolute;
            right: px-to-rem(40);
            bottom: px-to-rem(20);

            .waiting-room-info-data {
              padding-left: px-to-rem(20);

              .title {
                margin: 0 0 px-to-rem(10);
                font-size: px-to-rem(24);
                color: rgba(map-get($main-colors, 'light'), 0.4);

                @include media-breakpoint-up(lg) {
                  font-size: px-to-rem(36);
                }
              }

              .date {
                margin: 0;
                color: map-get($main-colors, 'light');
                font-size: px-to-rem(24);

                @include media-breakpoint-up(lg) {
                  font-size: px-to-rem(36);
                }
              }
            }
          }
        }
      }
      .waiting-room-memoized-countdown {
        flex: 1 0 calc(75% - #{px-to-rem(1)});
        padding: px-to-rem(0.5);

        .hls-video-player {
          position: relative;

          .video-js {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .waiting-room-no-signal-wrapper {
        flex: 0 0 25%;
        display: flex;
        flex-direction: row;

        @include media-breakpoint-up(lg) {
          flex-direction: column;
        }

        .waiting-room-no-signal {
          width: calc(100% - 1px);
          height: calc(33.33% - #{px-to-rem(1)});
          padding: px-to-rem(0.5);
          flex: auto;

          @include media-breakpoint-up(lg) {
            flex: 1 0 25%;
          }

          video,
          .video-js {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .video-js {
            height: 0;
            padding-bottom: 56.25%;
          }
        }
      }
    }
  }
}
